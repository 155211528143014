/* Forms */

.form {
 display:flex;
 flex-direction: column;
 justify-content: center;
 align-items:center;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type="submit"] {
  font: inherit;
}

.form-group-names {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}


@media screen and (max-width: 900px) {
  .form-group-names {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;   
  } 

  .form-control{ 
    margin: 10 auto;   
    width: 310px;
  }

  .form-group{
    width:360px;
  }
 
}
