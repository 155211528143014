.header__container{
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin: 0 auto;
    width:80%    
}


@media screen and (max-width: 1500px) {
    .header__img{
        width: 1600px;
        border:1px solid gray;
        border-radius:6px;
    }

}

@media screen and (max-width: 1200px) {
    .header__img{
        width: 900px;
        border:1px solid gray;
        border-radius:6px;
    }

}

@media screen and (max-width: 930px) {
    .header__img{
        width: 800px;
        border:1px solid gray;
        border-radius:6px;
    }

}

@media screen and (max-width: 820px) {
    .header__img{
        width: 600px;
        border:1px solid gray;
        border-radius:6px;
    }
}

@media screen and (max-width: 620px) {
    .header__img{
        width: 400px;
        border:1px solid gray;
        border-radius:6px;
    }

}

@media screen and (max-width: 410px) {
    .header__img{
        width: 370px;
        border:1px solid gray;
        border-radius:6px;
    }

}
