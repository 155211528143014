.image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
}

.form-control-fileupload{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}