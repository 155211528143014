.form__container{
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: 100%;  
    border-radius: 5px;
    border:1px solid #afaaaa;
    margin-top: 60px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.form__containerButon{
    margin-top:25px;
}

.buttonCustom{
    color:white;
    background-color: rgb(54, 168, 82);
    padding:15px 10px;
    border: 1px rgb(128, 128, 128) solid;    
    border-radius: 4px;
    text-decoration: none;
}
