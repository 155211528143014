.form__containerButonN {
  margin-bottom: 50px;
}
.facultati__nameE {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 24px;
  text-decoration: underline;
}
.facultati__ulL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.facultati__liI {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.facultati__levelContainerR {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.facultati__licentaMasterFrecvNonContainerR {
  padding: 15px;
  background-color: floralwhite;
  display: flex;
  width: 100%;
}
