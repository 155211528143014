.ellipsis{
    border: 1px solid black;
    padding: 5px;
}

.table {
    table-layout: fixed;
    width: 1500px;
    border-collapse: collapse;
    border-spacing: 0;    
    border: 1px solid #ddd;
  }
  
  .ellipsis {
    
    /* Required for text-overflow to do anything */
 

    overflow:scroll;
    text-align: left;
    
  }

  /* style={{ height: "40%", width: "1200px",overflowX:'auto'} */