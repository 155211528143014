.form-control {
  margin: 1rem;
  margin-right: 2rem;
    
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {  
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 90%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

/* .form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: red;
}
 */

.form-control--invalid p {
  color: rgb(221, 19, 19);
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
@media screen and (max-width: 900px) {
  .form-control label {
    font-size: 14px;
  }
}
