.container{    
    width:70%;    
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .container{
        width: 95%;
    }
}