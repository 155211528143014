.navigator__container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    border-radius: 5px;
    border: 1px solid #afaaaa;
    overflow: hidden;     
    border: 1px solid rgb(54, 168, 82);   
}
.buttonCustomNavigator{
    color:white;
    text-align: center;
    background-color: rgb(54, 168, 82);
    padding:5px 0px;
    width: 100%;
    font: inherit;
  padding: 0.2rem 1.5rem;
  border: 1px solid rgb(54, 168, 82);
    text-decoration: none;
    background-color: rgb(54, 168, 82);
}
.buttonActive{
    color:rgb(54, 168, 82);
    background-color: rgb(255, 255, 255);
}