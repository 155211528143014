.text__container{
    border: 1px gray solid;
    padding:20px;
    border-radius:5px;
}
.files__container{
    border: 1px gray solid;
    padding:20px;
    border-radius:5px;
}
.text__label{
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-right: 20px;;
    font-size: 16px;
}
.text__data{
    font-size: 17px;
    color:rgb(0, 156, 21);
    padding-left:4px;
}
.text__data--inline{
    font-size: 17px;
    color:rgb(0, 156, 21);
    padding-left:10px;
}

.text__a--link{
    font: bold 11px Arial;
  text-decoration: none;
  background-color: #EEEEEE;
  color: #333333;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #CCCCCC;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #CCCCCC;
  border-radius: 3px;
}

.validateUser{
    font-size: 20px;
    border:1px solid green;
    border-radius: 5px;
    height: 30px;
    font-weight: bold;
}

.validateUser span{
    margin-left:10px;
}
