.updocs__container {
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: 100%;  
    border-radius: 5px;
    border:1px solid #afaaaa;
    margin-top: 40px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.updocs__maincontainer{
    display:flex;
    justify-content: center;
    align-items: center;
}
.updocs__labelinput{   
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    margin-bottom:15px;
    border: 1px gray solid;
    border-radius: 6px;
    padding: 0 20px;
    width: 800px;
}
.updocs__label{ 
    font-size: 20px;   
    margin-right: 22px;
}

.updocs__input{
    
}

.form-uploadDoc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.form-group-uploaddoc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1215px) {
    .updocs__labelinput{
        width: 600px;
    }
}

@media screen and (max-width: 950px) {
    .updocs__labelinput{
        width: 400px;
    }
}

@media screen and (max-width: 700px) {
    .updocs__labelinput{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border:1px gray solid;
        margin-left: 0px;
        width: 300px;
    }
    .updocs__label{ 
        font-size: 16px;   
        margin-right: 22px;
    }
    .updocs__input{
        margin:10px;
        margin-left: 110px;
    }
}

