.facultati__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #afaaaa;
  margin-top: 60px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.facultati__ul {
  background-color: rgb(224, 247, 224);
  border-radius: 8px;
  list-style-type: none;
  padding: 5px 20px;
}

.color1:hover {
  background-color: rgb(195, 243, 195);
  border-radius: 5px;
  border: 1px rgb(195, 243, 195) solid;
}

.facultati_li {
  text-align: center;
  vertical-align: center;
}
.facultati__label {
  display: block;
  padding-left: 15px;
  font-size: 22px;
  margin-bottom: 7px;
}

.studiu__label {
  font-size: 18px;
  position: relative;
  left: 20px;
  padding-right:20px;
  
}

.facultati__input {
  width: 23px;
  height: 23px;
  padding: 0;
  margin-right: 10px;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}

.facultati__levelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
  left: -90px;
}

.facultati__licentaMasterFrecvNonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color:rgb(184, 197, 185);
   border-radius: 5px;
}

.facultati__licentaFrecvNonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.facultati__masterFrecvContainer {  
  padding-right: 20px;
}

.facultati__ifLicentaStudii {
  /* border: 1px gray solid; */
  margin-bottom: 0px;
}

.facultati__ifrLicentaStudii {
  /* border: 1px gray solid; */
  margin-bottom: 0px;
}

.facultati__ifMasterStudii {
  border: 1px gray solid;
  margin-bottom: 40px;
}

.form__containerButon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonCustom {
  color: white;
  background-color: rgb(54, 168, 82);
  padding: 15px 10px;
  border: 1px rgb(128, 128, 128) solid;
  border-radius: 4px;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  .facultati__label {
    font-size: 18px;
  }
  .color2 {
    font-size: 17px;
  }
  .facultati__input {
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 7px;
    vertical-align: bottom;
    position: relative;
    top: 0px;
  }
  .studiu__label {
    font-size: 15px;
    position: relative;
    left: 20px;
  }
  .facultati__licentaFrecvNonContainer {
    margin-bottom: 40px;
  }
  .facultati__masterFrecvContainer {
    margin-bottom: 40px;
    padding-right: 20px;
  }

  .facultati__levelContainer {
    position: relative;
    left: -50px;
  }
}
